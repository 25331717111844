





































import { namespace } from 'vuex-class';
import {
  Component, Vue, Watch, Prop, Ref
} from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/lib-api';
import {
  AppAction,
  AppActionType,
  AppState,
  DrawerTab
} from '@/store/modules/app/types';
import {
  CircuitsAction, CircuitsActionType, CircuitsGetter, CircuitsGetterType, CircuitsState
} from '@/store/modules/circuits/types';
import { getDutyInfo } from '@/api/common';
import { DevicesGetter, DevicesGetterType, DevicesState } from '@/store/modules/devices/types';
import OverwriteCard from '../OverwriteCard/OverwriteCard.vue';
import { ScenariosState } from '@/store/modules/scenarios/types';
import { GroupsState } from '@/store/modules/groups/types';

const devices = namespace('devices');
const circuits = namespace('circuits');
const groups = namespace('groups');
const app = namespace('app');
const scenarios = namespace('scenarios');

@Component({
  components: {
    OverwriteCard
  },
})
/**
 * @group Control Card Group
 * Circuit overwrite card
 */
export default class ControlTabCircuitOverwrite extends Vue {
  @Ref() overwriteCardRef!: any

  // enable user inputs (duty value and overwrite end date)
  @Prop(Boolean) readonly manualControl!: boolean;

  @app.State
  selectedTab!: AppState['selectedTab'];

  @circuits.State
  circuits!: CircuitsState['circuits'];

  @devices.State
  selectedMarkerId!: DevicesState['selectedMarkerId'];

  @groups.State
  selectedGroupUuid!: GroupsState['selectedGroupUuid'];

  @scenarios.State
  scenarios!: ScenariosState['scenarios'];

  @devices.Getter(DevicesGetter.GetDevice)
  getDevice!: DevicesGetterType['GET_DEVICE'];

  @devices.Getter(DevicesGetter.GetDeviceById)
  getDeviceById!: DevicesGetterType['GET_DEVICE_BY_ID'];

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @circuits.Getter(CircuitsGetter.GetCircuitById)
  getCircuitById!: CircuitsGetterType['GET_CIRCUIT_BY_ID'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @circuits.Action(CircuitsAction.SetSelectedCircuitID)
  setSelectedCircuitID!: CircuitsActionType['SET_SELECTED_CIRCUIT_ID'];

  @circuits.Action(CircuitsAction.SetSelectedCircuitUuid)
  setSelectedCircuitUuid!: CircuitsActionType['SET_SELECTED_CIRCUIT_UUID'];

  @circuits.Action(CircuitsAction.OverwriteCircuitSettings)
  overwriteCircuitSettings!: CircuitsActionType['OVERWRITE_CIRCUIT_SETTINGS'];

  duty = 0;

  endDate: string | null = null // ISO string

  message = {
    status: 'success',
    content: [] as String[]
  }

  scenarioName: string = '';

  selectedCircuitId: number | null = null;

  dutyInfo: Models.Misc.DutyInfo | null = null;

  timeoutForFetchId = '' as any;

  isDefaultScenario: boolean | null = false;

  get overwriteCardProps() {
    return {
      // disable any user inputs when the ControlSwitch is toggled off
      disabled: !this.manualControl,
      // options with available circuits
      selectOptions: this.selectOptions,
      // placeholder used when no circuit is selected
      selectPlaceholder: this.$t('actions.selectCircuit'),
      // selected circuit's ID (synced)
      selectedItemID: this.selectedCircuitId,
      // duty of current overwrite setting (synced)
      duty: this.duty,
      // end date of current overwrite setting (synced)
      endDate: this.endDate,
      // disable overwrite button in lamp overwriteCard unless lamp status is online (true)
      lampIsOnline: null,
      // disable automatic control button if is defaultScenario
      isDefaultScenario: this.isDefaultScenario,
    };
  }

  get overwriteCardListeners() {
    return {
      // update selected circuit's ID
      'update:selectedItemID': (id: number) => { this.selectedCircuitId = id; },
      // update overwrite's duty
      'update:duty': (duty: number) => { this.duty = duty; },
      // update overwrite's end date
      'update:endDate': (date: string) => { this.endDate = date; },
      // save overwrite settings
      overwrite: () => { this.sendOverwrite(true, this.duty, this.endDate); },
      // remove existing overwrite settings from the selected citcuit
      disableOverwrite: () => { this.sendOverwrite(false, null, null); },
    };
  }

  get selectOptions() {
    return this.circuits.filter((obj) => obj.scenario_id).map((d) => ({
      text: d.name,
      value: d.id,
    }));
  }

  get selectedCircuit() {
    if (this.selectedTab) return null;
    return this.selectedCircuitId ? this.getCircuitById(this.selectedCircuitId) : null;
  }

  fetchDutyInfo(uuid: string) {
    this.switchLoader();
    const defaultScenarioIuid = this.scenarios.find((s) => s.is_default)?.uuid;
    getDutyInfo('circuits', uuid)
      .then((dutyInfo) => {
        this.dutyInfo = dutyInfo;
        this.duty = dutyInfo.duty;
        // check if current duty info has been set by overwriting
        if (dutyInfo.config === 'overwrite') {
          this.isDefaultScenario = false;
          this.endDate = dutyInfo.overwrite_end_date as string;
          this.message = {
            status: 'success',
            content: ['dutySource[1]', 'main.circuit']
          };
        // check if current duty info comes from a custom or default scenario
        // in this case sceneario_id should be present on the DutyInfo
        } else if (dutyInfo.config === 'scenario' && dutyInfo.scenario_id) {
          // if scenario_id equals to defaultScenario uuid, duty value has been set by the default scenario
          if (dutyInfo.scenario_id === defaultScenarioIuid) {
            this.isDefaultScenario = true;
            this.message = {
              status: 'success',
              content: ['dutySource[3]', 'main.circuit']
            };
          } else {
            const scenario = this.scenarios.find((s) => s.uuid === dutyInfo.scenario_id);
            this.scenarioName = ` ${dutyInfo?.scenario_name}`;
            this.isDefaultScenario = scenario ? scenario?.is_default : false;
            this.message = {
              status: 'success',
              content: ['dutySource[2]', 'main.circuit']
            };
          }
        // if there is no scenario_id, duty value has been set by the default scenario
        } else {
          this.isDefaultScenario = true;
          this.message = {
            status: 'success',
            content: ['dutySource[3]', 'main.circuit']
          };
        }
      })
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
        this.clearTimeoutForFetch();
        this.timeoutForFetchId = this.timeoutForFetch(this.endDate);
      });
  }

  sendOverwrite(enabled: boolean, duty: number | null, endDate: string | null) {
    if (!this.selectedCircuit) return;
    this.clearState();
    this.switchLoader();
    const overwritePayload: Models.Circuits.Model = {
      ...this.selectedCircuit,
      overwrite_enabled: enabled,
      overwrite_duty: duty,
      overwrite_end_date: endDate ? new Date(endDate) : null,
    };
    this.overwriteCircuitSettings(overwritePayload)
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
      });
  }

  switchLoader() {
    // terrible, and works, which is even worse..
    // TODO find a better solution
    this.overwriteCardRef.loaderRef.switchLoader();
  }

  learnMoreOnCircuit() {
    this.selectedCircuitId = null;
    this.setSelectedCircuitID(this.selectedCircuitId);
    this.setSelectedCircuitUuid(null);
    this.setSelectedTab(DrawerTab.Creator);
  }

  timeoutForFetch(endDate: string | null) {
    if (endDate && new Date(endDate) > new Date()) {
      setTimeout(() => {
        this.clearState();
        this.fetchDutyInfo(this.selectedCircuit?.uuid as string);
      }, Number(new Date(endDate)) - Number(new Date()) + 5000);
    }
  }

  clearTimeoutForFetch() {
    clearTimeout(this.timeoutForFetchId);
  }

  clearState() {
    this.duty = 0;
    this.endDate = null;
    this.message = {
      status: 'success',
      content: []
    };
    this.scenarioName = '';
    this.isDefaultScenario = null;
  }

  @Watch('selectedCircuit', { deep: true })
  onSelectedCircuitChange(circuit: Models.Circuits.Model) {
    this.clearState();
    this.clearTimeoutForFetch();
    this.setSelectedCircuitUuid(circuit?.uuid ?? null);
    if (circuit && !this.selectedTab) {
      this.fetchDutyInfo(circuit.uuid as string);
    }
  }

  // if the user selects a group by selecting it in ControlTabGroupOverwrite
  // change the selected circuit to null if it does not belong to the group
  @Watch('selectedGroupUuid')
  onSelectedGroupUuidChange(groupUuid: string) {
    if (!this.selectedCircuit) return;
    if (!groupUuid) return;
    if (groupUuid !== this.selectedCircuit?.group_id) this.selectedCircuitId = null;
  }

  // if the user selects a lamp by clicking on it on the map, or by selecting it in ControlTabLampOverwrite
  // fetch and display duty info of the circuit it belongs to
  @Watch('selectedMarkerId')
  onSelectedMarkerIdChange(markerId: number | null) {
    // this.clearState();
    this.selectedCircuitId = null;
    if (!markerId) this.setSelectedCircuitID(null);
    if (markerId !== null) {
      const { circuit_id } = this.getDeviceById(markerId) as Models.Devices.Lamp; // TODO instead of saving selectedMarkerId, save the whole object in the store ?
      const circuit = this.getCircuit(circuit_id as string);
      this.setSelectedCircuitID(circuit?.id ?? null);
    }
  }
}
