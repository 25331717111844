
































import { namespace } from 'vuex-class';
import {
  Component, Vue, Watch, Prop, Ref
} from 'vue-property-decorator';
import { AppAction, AppActionType, DrawerTab } from '@/store/modules/app/types';
import {
  GroupsAction, GroupsActionType, GroupsGetter, GroupsGetterType, GroupsState
} from '@/store/modules/groups/types';
import { getDutyInfo } from '@/api/common';
import { DevicesGetter, DevicesGetterType, DevicesState } from '@/store/modules/devices/types';
import { Models } from '@mtap-smartcity/lib-api';
import { CircuitsGetter, CircuitsGetterType, CircuitsState } from '@/store/modules/circuits/types';
import OverwriteCard from '../OverwriteCard/OverwriteCard.vue';
import { ScenariosState } from '@/store/modules/scenarios/types';
import { CreatorTabs } from '@/constants/main_card_inner_tabs';

const devices = namespace('devices');
const circuits = namespace('circuits');
const groups = namespace('groups');
const app = namespace('app');
const scenarios = namespace('scenarios');

@Component({
  components: {
    OverwriteCard
  },
})
/**
 * @group Control Card Group
 * Group overwrite card
 */
export default class ControlTabGroupOverwrite extends Vue {
  @Ref() overwriteCardRef!: any

  // enable user inputs (duty value and overwrite end date)
  @Prop(Boolean) readonly manualControl!: boolean;

  @groups.State
  groups!: GroupsState['groups'];

  @devices.State
  selectedMarkerId!: DevicesState['selectedMarkerId'];

  @devices.State
  selectedDeviceUuid!: DevicesState['selectedDeviceUuid'];

  @circuits.State
  selectedCircuitUuid!: CircuitsState['selectedCircuitUuid'];

  @scenarios.State
  scenarios!: ScenariosState['scenarios'];

  @devices.Getter(DevicesGetter.GetDevice)
  getDevice!: DevicesGetterType['GET_DEVICE'];

  @devices.Getter(DevicesGetter.GetDeviceById)
  getDeviceById!: DevicesGetterType['GET_DEVICE_BY_ID'];

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @groups.Getter(GroupsGetter.GetGroup)
  getGroup!: GroupsGetterType['GET_GROUP'];

  @groups.Getter(GroupsGetter.GetGroupById)
  getGroupById!: GroupsGetterType['GET_GROUP_BY_ID'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @app.Action(AppAction.SetSelectedCreatorTab)
  setSelectedCreatorTab!: AppActionType['SET_SELECTED_CREATOR_TAB']

  @groups.Action(GroupsAction.SetSelectedGroupID)
  setSelectedGroupId!: GroupsActionType['SET_SELECTED_GROUP_ID'];

  @groups.Action(GroupsAction.SetSelectedGroupUuid)
  setSelectedGroupUuid!: GroupsActionType['SET_SELECTED_GROUP_UUID'];

  @groups.Action(GroupsAction.OverwriteGroupSettings)
  overwriteGroupSettings!: GroupsActionType['OVERWRITE_GROUP_SETTINGS'];

  selectedGroupId: number | null = null;

  duty = 0;

  endDate: string | null = null // ISO string

  message = {
    status: 'success',
    content: [] as String[]
  }

  scenarioName: string = ''

  dutyInfo: Models.Misc.DutyInfo | null = null;

  timeoutForFetchId = '' as any;

  isDefaultScenario: boolean | null = false;

  get overwriteCardProps() {
    return {
      // disable any user inputs when the ControlSwitch is toggled off
      disabled: !this.manualControl,
      // options with available groups
      selectOptions: this.selectOptions,
      // placeholder used when no group is selected
      selectPlaceholder: this.$t('actions.selectGroup'),
      // selected group's ID (synced)
      selectedItemID: this.selectedGroupId,
      // duty of current overwrite setting (synced)
      duty: this.duty,
      // end date of current overwrite setting (synced)
      endDate: this.endDate,
      // disable overwrite button in lamp overwriteCard unless lamp status is online (true)
      lampIsOnline: null,
      // disable automatic control button if is defaultScenario
      isDefaultScenario: this.isDefaultScenario,
    };
  }

  get overwriteCardListeners() {
    return {
      // update selected groups's ID
      'update:selectedItemID': (id: number) => { this.selectedGroupId = id; },
      // update overwrite's end date
      'update:endDate': (date: string) => { this.endDate = date; },
      // update overwrite's duty
      'update:duty': (duty: number) => { this.duty = duty; },
      // save overwrite settings
      overwrite: () => { this.sendOverwrite(true, this.duty, this.endDate); },
      // remove existing overwrite settings from the selected group
      disableOverwrite: () => { this.sendOverwrite(false, null, null); },
    };
  }

  get selectOptions() {
    return this.groups.filter((g) => g.id).map((g) => ({
      text: g.name,
      value: g.id,
    }));
  }

  get selectedGroup() {
    return this.selectedGroupId ? this.getGroupById(this.selectedGroupId) : null;
  }

  fetchDutyInfo(uuid: string) {
    this.switchLoader();
    const defaultScenarioUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    getDutyInfo('groups', uuid)
      .then((dutyInfo) => {
        this.dutyInfo = dutyInfo;
        this.duty = dutyInfo.duty;
        // check if current duty info has been set by overwriting
        if (dutyInfo.config === 'overwrite') {
          this.isDefaultScenario = false;
          this.endDate = dutyInfo.overwrite_end_date as string;
          this.message = {
            status: 'success',
            content: ['dutySource[1]', 'main.group']
          };
        // check if current duty info comes from a custom or default scenario
        // in this case scenenario_id should be present on the DutyInfo
        } else if (dutyInfo.config === 'scenario' && dutyInfo.scenario_id) {
          // if scenario_id equals to defaultScenario uuid, duty value has been set by the default scenario
          if (dutyInfo.scenario_id === defaultScenarioUuid) {
            this.isDefaultScenario = true;
            this.message = {
              status: 'success',
              content: ['dutySource[3]', 'main.group']
            };
          } else {
            const scenario = this.scenarios.find((s) => s.uuid === dutyInfo.scenario_id);
            this.scenarioName = ` ${dutyInfo?.scenario_name}`;
            this.isDefaultScenario = scenario ? scenario?.is_default : false;
            this.message = {
              status: 'success',
              content: ['dutySource[2]', 'main.group']
            };
          }
        // if there is no scenario_id, duty value has been set by the default scenario
        } else {
          this.isDefaultScenario = true;
          this.message = {
            status: 'success',
            content: ['dutySource[3]', 'main.group']
          };
        }
      })
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
        this.clearTimeoutForFetch();
        this.timeoutForFetchId = this.timeoutForFetch(this.endDate);
      });
  }

  sendOverwrite(enabled: boolean, duty: number | null, endDate: string | null) {
    if (!this.selectedGroup) return;
    this.clearState();
    this.switchLoader();
    const overwritePayload: Models.Groups.Model = {
      ...this.selectedGroup,
      overwrite_enabled: enabled,
      overwrite_duty: duty,
      overwrite_end_date: endDate ? new Date(endDate) : null
    };
    this.overwriteGroupSettings(overwritePayload)
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
      });
  }

  switchLoader() {
    // terrible, and works, which is even worse..
    // TODO find a better solution
    this.overwriteCardRef.loaderRef.switchLoader();
  }

  learnMoreOnGroup() {
    this.selectedGroupId = null;
    this.setSelectedGroupId(this.selectedGroupId);
    this.setSelectedGroupUuid(null);
    this.setSelectedTab(DrawerTab.Creator);
    this.setSelectedCreatorTab(CreatorTabs.Groups);
  }

  timeoutForFetch(endDate: string | null) {
    if (endDate && new Date(endDate) > new Date()) {
      setTimeout(() => {
        this.clearState();
        this.fetchDutyInfo(this.selectedGroup?.uuid as string);
      }, Number(new Date(endDate)) - Number(new Date()) + 5000);
    }
  }

  clearTimeoutForFetch() {
    clearTimeout(this.timeoutForFetchId);
  }

  clearState() {
    this.duty = 0;
    this.endDate = null;
    this.message = {
      status: 'success',
      content: []
    };
    this.scenarioName = '';
    this.isDefaultScenario = null;
  }

  @Watch('selectedGroup', { deep: true })
  onSelectedGroupChange(group: Models.Groups.Model) {
    this.clearState();
    this.clearTimeoutForFetch();
    this.setSelectedGroupUuid(group?.uuid ?? null);
    if (group) {
      this.fetchDutyInfo(group.uuid as string);
    }
  }

  // if the user selects a lamp by clicking on it on the map, or by selecting it in ControlTabLampOverwrite
  // fetch and display duty info of the group it belongs to
  @Watch('selectedDeviceUuid')
  onSelectedDeviceUuidChange(deviceUuid: string) {
    if (!deviceUuid) return;
    this.selectedGroupId = null;
    if (deviceUuid) {
      const { circuit_id } = this.getDevice(deviceUuid) as Models.Devices.Lamp;
      if (circuit_id) {
        const { group_id } = this.getCircuit(circuit_id) as Models.Circuits.Model;
        if (group_id) {
          this.selectedGroupId = this.getGroup(group_id)!.id!;
        }
      } else {
        this.selectedGroupId = null;
      }
    }
  }

  // if the user selects a circuit by clicking lamp on the map, or by selecting it in ControlTabCircuitOverwrite
  // fetch and display duty info of the group it belongs to
  @Watch('selectedCircuitUuid')
  onSelectedCircuitUuidChange(circuitUuid: string) {
    if (!circuitUuid) return;
    this.selectedGroupId = null;
    if (circuitUuid) {
      const { group_id } = this.getCircuit(circuitUuid) as Models.Circuits.Model;
      if (group_id) {
        this.selectedGroupId = this.getGroup(group_id)!.id!;
      }
    } else {
      this.selectedGroupId = null;
    }
  }
}
