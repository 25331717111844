













































import { namespace } from 'vuex-class';
import {
  Component, Vue, Watch, Prop, Ref
} from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/lib-api';
import {
  AppAction, AppActionType, DrawerTab, AppGetter, AppGetterType 
} from '@/store/modules/app/types';
import {
  DevicesAction, DevicesActionType, DevicesGetter, DevicesGetterType, DevicesState
} from '@/store/modules/devices/types';
import { getDutyInfo } from '@/api/common';
import { hasCoordinates, isLamp } from '@/utils/type_check';
import OverwriteCard from '../OverwriteCard/OverwriteCard.vue';
import { ScenariosState } from '@/store/modules/scenarios/types';
import {
  TelemetryAction, TelemetryActionType, TelemetryGetter, TelemetryGetterType
} from '@/store/modules/telemetry/types';
import { CircuitsGetter, CircuitsGetterType, CircuitsState } from '@/store/modules/circuits/types';
import { GroupsState } from '@/store/modules/groups/types';
import { isInAllowedDelay } from '@/utils/allowed_delay';

const devices = namespace('devices');
const circuits = namespace('circuits');
const groups = namespace('groups');
const app = namespace('app');
const scenarios = namespace('scenarios');
const telemetry = namespace('telemetry');

@Component({
  components: {
    OverwriteCard
  },
})
/**
 * @group Control Card Group
 * Lamp overwrite card
 */
export default class ControlTabLampOverwrite extends Vue {
  @Ref() overwriteCardRef!: any

  // enable user inputs (duty value and overwrite end date)
  @Prop(Boolean) readonly manualControl!: boolean;

  @devices.State
  devices!: DevicesState['devices'];

  @devices.State
  selectedMarkerId!: DevicesState['selectedMarkerId'];

  @circuits.State
  selectedCircuitUuid!: CircuitsState['selectedCircuitUuid'];

  @groups.State
  selectedGroupUuid!: GroupsState['selectedGroupUuid'];

  @scenarios.State
  scenarios!: ScenariosState['scenarios'];
  
  @app.Getter(AppGetter.GetRuntimeConfig)
  runtimeConfig!: AppGetterType['GET_RUNTIME_CONFIG'];

  @devices.Getter(DevicesGetter.GetDevice)
  getDevice!: DevicesGetterType['GET_DEVICE'];

  @devices.Getter(DevicesGetter.GetDeviceById)
  getDeviceById!: DevicesGetterType['GET_DEVICE_BY_ID'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @app.Action(AppAction.SetMapFocusPoint)
  setMapFocusPoint!: AppActionType['SET_MAP_FOCUS_POINT']

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @app.Action(AppAction.SetSelectedDevicesTab)
  setSelectedDevicesTab!: AppActionType['SET_SELECTED_DEVICES_TAB'];

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  @devices.Action(DevicesAction.SetSelectedId)
  setSelectedId!: DevicesActionType['SET_SELECTED_ID']

  @devices.Action(DevicesAction.SetSelectedDeviceUuid)
  setSelectedDeviceUuid!: DevicesActionType['SET_SELECTED_DEVICE_UUID']

  @devices.Action(DevicesAction.OverwriteDeviceSettings)
  overwriteDeviceSettings!: DevicesActionType['OVERWRITE_DEVICE_SETTINGS'];

  @telemetry.Action(TelemetryAction.FetchDeviceTelemetry)
  fetchDeviceTelemetry!: TelemetryActionType['FETCH_DEVICE_TELEMETRY'];

  selectedLampId: number | null = null;

  duty = 0;

  endDate: string | null = null // ISO string

  message = {
    status: 'success',
    content: [] as String[]
  }

  scenarioName: string = ''

  dutyInfo: Models.Misc.DutyInfo | null = null;

  timeoutForFetchId = '' as any;

  isDefaultScenario: boolean | null = false;

  get telemetryData() {
    if (!this.selectedLampId) return null;
    return this.getLastTelemetry(this.selectedLamp.object_id, this.selectedLamp.device_type);
  }

  get lampIsOnline() {
    return isInAllowedDelay(this.telemetryData, this.runtimeConfig);
  }

  get overwriteCardProps() {
    return {
      // disable any user inputs when the ControlSwitch is toggled off
      disabled: !this.manualControl,
      // options with available lamps
      selectOptions: this.selectOptions,
      // placeholder used when no lamp is selected
      selectPlaceholder: this.$t('actions.selectDevice'),
      // selected lamp's ID (synced)
      selectedItemID: this.selectedLampId,
      // duty of current overwrite setting (synced)
      duty: this.duty,
      // end date of current overwrite setting (synced)
      endDate: this.endDate,
      // disable overwrite button in lamp overwriteCard unless lamp status is online (true)
      lampIsOnline: this.lampIsOnline,
      // disable automatic control button if is defaultScenario
      isDefaultScenario: this.isDefaultScenario,
    };
  }

  get overwriteCardListeners() {
    return {
      // update selected lamp's ID
      'update:selectedItemID': (id: number) => { this.selectedLampId = id; },
      // update overwrite's end date
      'update:endDate': (date: string) => { this.endDate = date; },
      // update overwrite's duty
      'update:duty': (duty: number) => { this.duty = duty; },
      // save overwrite settings
      overwrite: () => { this.sendOverwrite(true, this.duty, this.endDate); },
      // remove existing overwrite settings from the selected lamp
      disableOverwrite: () => { this.sendOverwrite(false, null, null); },
    };
  }

  get selectOptions() {
    return this.devices.filter(isLamp).filter((obj) => obj.scenario_id).map((d) => ({
      text: d.object_id,
      value: d.id,
    }));
  }

  get selectedLamp() {
    // TODO || null ?
    return this.selectedLampId ? (this.getDeviceById(this.selectedLampId) as Models.Devices.Lamp) : {} as Models.Devices.Lamp;
  }

  fetchDutyInfo(id: number) {
    const device = this.getDeviceById(id);
    const defaultScenarioIuid = this.scenarios.find((s) => s.is_default)?.uuid;
    if (!device || !this.lampIsOnline) return;
    this.switchLoader();
    getDutyInfo('devices', device.uuid as string)
      .then((dutyInfo) => {
        this.dutyInfo = dutyInfo;
        this.duty = dutyInfo.duty;
        // check if current duty info has been set by overwriting
        if (dutyInfo.config === 'overwrite') {
          this.isDefaultScenario = false;
          this.endDate = dutyInfo.overwrite_end_date as string;
          this.message = {
            status: 'success',
            content: ['dutySource[1]', 'main.lamp']
          };
          this.clearTimeoutForFetch();
          this.timeoutForFetchId = this.timeoutForFetch(this.endDate);
        // check if current duty info comes from a custom or default scenario
        // in this case scenenario_id should be present on the DutyInfo
        } else if (dutyInfo.config === 'scenario' && dutyInfo.scenario_id) {
          // if scenario_id equals to defaultScenario uuid, duty value has been set by the default scenario
          if (dutyInfo.scenario_id === defaultScenarioIuid) {
            this.isDefaultScenario = true;
            this.message = {
              status: 'success',
              content: ['dutySource[3]', 'main.lamp']
            };
            this.clearTimeoutForFetch();
          } else {
            const scenario = this.scenarios.find((s) => s.uuid === dutyInfo.scenario_id);
            this.scenarioName = ` ${dutyInfo?.scenario_name}`;
            this.isDefaultScenario = scenario ? scenario?.is_default : false;
            this.message = {
              status: 'success',
              content: ['dutySource[2]', 'main.lamp']
            };
            this.clearTimeoutForFetch();
            this.timeoutForFetchId = this.timeoutForFetch(this.endDate);
          }
        // if there is no scenario_id, duty value has been set by the default scenario
        } else {
          this.isDefaultScenario = true;
          this.message = {
            status: 'success',
            content: ['dutySource[3]', 'main.lamp']
          };
          this.clearTimeoutForFetch();
        }
      })
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
      });
  }

  sendOverwrite(enabled: boolean, duty: number | null, endDate: string | null) {
    if (!this.selectedLamp || !this.selectedLampId) return;
    this.clearState();
    this.switchLoader();
    const overwritePayload: any = {
      ...this.selectedLamp,
      overwrite_enabled: enabled,
      overwrite_duty: duty,
      overwrite_end_date: endDate ? new Date(endDate) : null,
    };
    this.overwriteDeviceSettings(overwritePayload)
      .then(() => {
        this.fetchDutyInfo(this.selectedLampId as number);
        this.clearTimeoutForFetch();
        this.timeoutForFetchId = this.timeoutForFetch(endDate);
      })
      .catch(() => {
        this.isDefaultScenario = null;
        this.message = {
          status: 'error',
          content: ['error', '']
        };
      })
      .finally(() => {
        this.switchLoader();
      });
  }

  switchLoader() {
    // terrible, and works, which is even worse..
    // TODO find a better solution
    this.overwriteCardRef.loaderRef.switchLoader();
  }

  learnMoreOnLamp() {
    this.setSelectedId(this.selectedLampId);
    this.setSelectedDeviceUuid(null);
    this.setSelectedDevicesTab('lamp');
    this.setSelectedTab(DrawerTab.Devices);
  }

  timeoutForFetch(endDate: string | null) {
    if (endDate && new Date(endDate) > new Date()) {
      setTimeout(() => {
        this.clearState();
        this.fetchDutyInfo(this.selectedLampId as number);
      }, Number(new Date(endDate)) - Number(new Date()) + 5000);
    }
  }

  clearTimeoutForFetch() {
    clearTimeout(this.timeoutForFetchId);
  }

  clearState() {
    this.duty = 0;
    this.endDate = null;
    this.message = {
      status: 'success',
      content: []
    };
    this.scenarioName = '';
    this.isDefaultScenario = null;
  }

  @Watch('selectedLamp')
  async onSelectedLampChange(newLamp: Models.Devices.Lamp, oldLamp: Models.Devices.Lamp) {
    this.clearState();
    this.clearTimeoutForFetch();
    this.setSelectedDeviceUuid(newLamp?.uuid ?? null);
    if (newLamp.id === oldLamp.id) return;
    if (!newLamp.id) return;
    this.fetchDutyInfo(newLamp.id!);
    this.setSelectedMarkerId(newLamp.id);
    const lampParametersDevice = newLamp?.parameters.device;
    if (
      hasCoordinates(lampParametersDevice)
      && lampParametersDevice.latitude
      && lampParametersDevice.longitude
    ) {
      this.setMapFocusPoint({
        lat: lampParametersDevice.latitude,
        lng: lampParametersDevice.longitude,
      });
    }
    try {
      await this.fetchDeviceTelemetry({ objectId: newLamp.object_id });
    } catch (e) {
      console.error(e);
    }
  }

  // display duty lamp's duty info when user clicks on lamp marker
  @Watch('selectedMarkerId')
  onSelectedMarkerIdChange(markerId: number | null) {
    this.selectedLampId = markerId;
  }

  // if the user selects a circuit by selecting it in ControlTabGroupOverwrite
  // change the selected lamp to null if it does not belong to the circuit
  @Watch('selectedCircuitUuid')
  onSelectedCircuitUuidChange(circuitUuid: string) {
    if (!this.selectedLamp) return;
    if (!circuitUuid) return;
    if (circuitUuid !== this.selectedLamp?.circuit_id) this.selectedLampId = null;
  }

  // if the user selects a group by selecting it in ControlTabCircuitOverwrite
  // change the selected lamp to null if it does not belong to the group
  @Watch('selectedGroupUuid')
  onSelectedGroupUuidChange(groupUuid: string) {
    if (!this.selectedLamp) return;
    if (!groupUuid) return;
    if (groupUuid && this.selectedLamp.circuit_id) {
      const { group_id } = this.getCircuit(this.selectedLamp.circuit_id) as Models.Circuits.Model;
      if (group_id !== groupUuid) {
        this.selectedLampId = null;
      }
    } else {
      this.selectedLampId = null;
    }
  }
}
